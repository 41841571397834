<template>
	<v-sheet height="100%" class="background">
		<v-container class="fill-height justify-center" fluid>
			<v-row align="center" justify="center">
				<v-col cols="12" sm="8" md="8" lg="6">
					<v-sheet class="with-radius">
						<v-card-text class="pa-0">
							<v-row class="ma-0">
								<v-col cols="12"
									><div class="my-4 mx-auto text-center">
										<div class="text-h4 d-flex justify-center align-center">
											<img src="/static/default/logo-cloud.png" width="60" alt="logo" /><b
												>Franchising Cloud</b
											>
										</div>
										<div class="subtitle-1 mt-3">{{ stepDetail.title }}</div>
										<div class="text-body-2 px-2">{{ stepDetail.subtitle }}</div>
									</div></v-col
								>

								<v-col cols="12" md="6" class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
									<v-sheet height="100%" color="transparent">
										<v-row align="center" justify="center" class="fill-height ma-0">
											<v-img :src="stepDetail.img" :lazy-src="stepDetail.img"/>
										</v-row>
									</v-sheet>
								</v-col>
								<!-- 2 -->
								<v-col cols="12" md="6" class="px-5 pos-relative">
									<v-stepper v-model="recoveryStep" class="transparent clear-shadow">
										<v-stepper-header class="hide">
											<v-stepper-step :complete="recoveryStep > 1" step="1"
												>1</v-stepper-step
											>
											<v-stepper-step :complete="recoveryStep > 2" step="2"
												>2</v-stepper-step
											>
											<v-stepper-step step="3">3</v-stepper-step>
										</v-stepper-header>

										<v-stepper-items>
											<v-stepper-content step="1" class="pa-0">
												<send-otp @next="recoveryStep = 2" v-if="recoveryStep === 1" />
											</v-stepper-content>

											<v-stepper-content step="2" class="pa-0">
												<verify-otp
													@next="
														recoveryStep = 3
														email = $event
													"
													v-if="recoveryStep === 2"
												/>
											</v-stepper-content>

											<v-stepper-content step="3">
												<reset-password
													@complete="completeProcess()"
													:email="email"
													v-if="recoveryStep === 3"
												/>
											</v-stepper-content>
										</v-stepper-items>
									</v-stepper>

									<v-btn icon absolute top right to="/login">
										<v-icon small>close</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-card-text>
					</v-sheet>
				</v-col>
			</v-row>
		</v-container>
		<v-snackbar v-model="snackbar" absolute top right color="success">
			<span>Password Reset Successfully</span>
			<v-icon dark>check_circle</v-icon>
		</v-snackbar>
	</v-sheet>
</template>

<script>
	export default {
		components: {
			SendOtp: () => import( './Partials/SendOtp' ),
			VerifyOtp: () => import( './Partials/VerifyOtp' ),
			ResetPassword: () => import( './Partials/ResetPassword' ),
		},
		data() {
			return {
				snackbar: false,
				recoveryStep: 2,
				email: '',
				stepsDetails: [
					{
						title: 'Recupera tu cuenta',
						subtitle: 'Introduce tu correo electrónico para restablecer tu contraseña ',
						img: '/static/illustator/forgot_password.png',
					},
					{
						title: 'Introduce el código',
						subtitle:
							'Hemos enviado un código de 6 dígitos a tu dirección de correo electrónico. Ingrese el código a continuación.',
						img: '/static/illustator/my_passcode.png',
					},
					{
						title: 'Introduce la nueva contraseña',
						subtitle: null,
						img: '/static/illustator/password.png',
					},
				],
			}
		},
		created() {
			this.checkStep()
		},
		computed: {
			stepDetail() {
				const index = this.recoveryStep - 1
				return this.stepsDetails[index]
			},
		},
		methods: {
			checkStep() {
				switch ( parseInt( this.$route.query.step ) ) {
					case 1:
						this.recoveryStep = 1
						break
					case 2:
						this.recoveryStep = 2
						break
					default:
						this.recoveryStep = 1
						break
				}
			},
			completeProcess() {
				this.snackbar = true
				this.$router.push( {
					name: 'LoginPage',
				} )
			},
		},
	}
</script>
<style lang="scss" scoped>
	.background {
		background-image: url('https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg');
	}
</style>
